<template>
  <div class="flex h-full justify-center">
    <div class="sm:w-max-3xl">
      <vs-table class="w-auto relative" ref="table">
        <template #notFound> You don't have any subscriptions yet. </template>
        <template #thead>
          <vs-tr>
            <vs-th class="!w-3">
              <i class="bx bx-check" />
            </vs-th>
            <vs-th class="!w-1/4"> Product </vs-th>
            <vs-th> Game </vs-th>
            <vs-th> Plan </vs-th>
            <vs-th class="!w-"> Key </vs-th>
            <vs-th> Valid Until </vs-th>
          </vs-tr>
        </template>
        <template #tbody v-if="subscriptions">
          <vs-tr :key="i" v-for="(tr, i) in subscriptions.docs" :data="tr">
            <vs-td>
              <i v-if="checkExpired(tr)" class="bx bx-x"></i>
              <i v-else class="bx bx-check"></i>
            </vs-td>
            <vs-td>
              {{
                tr.product &&
                (tr.product.name.length > NAME_MAX_LENGTH
                  ? tr.product.name.substring(0, NAME_MAX_LENGTH) + "..."
                  : tr.product.name)
              }}
            </vs-td>
            <vs-td>{{ tr.game.shortName }}</vs-td>
            <vs-td>
              {{ tr.plan && tr.plan.name }}
            </vs-td>
            <vs-td>
              {{ tr.key }}
            </vs-td>
            <vs-td> {{ tr.expiredAt | formatTimestamp }}</vs-td>
          </vs-tr>
        </template>
        <template #footer v-if="subscriptions">
          <vs-pagination v-model="page" :length="subscriptions.totalPages" />
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import { getMySubscriptions } from "@/services/ApiService";

export default {
  name: "Subscriptions",
  data: () => ({
    page: 1,
    max: 10,
    subscriptions: [],
    NAME_MAX_LENGTH: 50,
  }),
  methods: {
    async getSubscriptions() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.subscriptions = await getMySubscriptions(this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    checkExpired(subscription) {
      return subscription.expiredAt * 1000 <= Date.now();
    },
  },
  async mounted() {
    await this.getSubscriptions();
  },
  watch: {
    page() {
      this.getSubscriptions();
    },
  },
};
</script>
